import { SWAN_STYLE_KEY_MAP } from '@vp/swan'
import { useSwanStyleKeys, useUserContextTracker } from '@vp/ubik-context'
import { ContentfulContextProvider, DebugSection, ContentfulLink } from '@vp/mcs-react-tools'
import { TrackingProvider } from './hooks/TrackingContext'
import { SeoZone } from './components/SeoZone'

function trackUsedContext (props: any) {
  const tracker = useUserContextTracker()
  if (props.experimentKey) {
    tracker({
      type: 'experiment',
      name: props.experimentKey,
      variation: props.variationKey
    })
  }
  if (props.decision) {
    tracker({
      type: 'treatment',
      name: props.decision
    })
  }
  if (props.merchandisingCampaign) {
    tracker({
      type: 'campaign',
      name: props.merchandisingCampaign
    })
  }
}

export const Fragment = (props: any) => {
  const { seoZoneHeader, seoZoneTiles, seoZoneDescription } = props.pageData

  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.utility,
  ])

  trackUsedContext(props)

  const debugAvailability = {
    merchandisingCampaign: props.merchandisingCampaign,
    experimentKey: props.experimentKey,
    variationKey: props.variationKey,
    decision: props.decision,
  }
  const search = typeof window !== 'undefined' ? window.location.search : ''

  return (
    <ContentfulContextProvider space={props.contentfulMetadata.spaceId}>
      <ContentfulLink id={props.contentfulMetadata?.contentful_id} />
      <TrackingProvider>
        <DebugSection
          contentfulMetadata={props.contentfulMetadata}
          availability={debugAvailability}
          search={search}
        />
        <SeoZone header={seoZoneHeader} description={seoZoneDescription} tiles={seoZoneTiles} />
      </TrackingProvider>
    </ContentfulContextProvider>
  )
}
